import { Rating } from "@mui/material";

export default function Comment({ content }) {
    return (
        <>
            {/* component */}

            <div className="my-2 text-white bg-gray-900 rounded-2xl px-10 py-8 shadow-lg hover:shadow-2xl transition duration-500">
                <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-4 pb-3">
                        <div className="">
                            <img
                                className="w-12 h-12 rounded-full"
                                src={content.image}
                                alt=""
                            />
                        </div>
                        <div className="text-sm font-semibold">
                            {content.user} • <span className="font-normal">{content.timestamp}</span>
                        </div>
                    </div>
                </div>
                
                <div className="flex">
                    <Rating name="read-only" precision={0.25} value={content.calification} readOnly />
                </div>

                <p className="mt-4 text-md text-gray-200 text-left">
                    {content.comment}
                </p>
            </div>

        </>

    )
}